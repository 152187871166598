import {
  AppBar,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  Select,
  Switch,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@material-ui/core/';

import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import { Alert, TabContext, TabPanel } from '@material-ui/lab';
import { Image, Transformation } from 'cloudinary-react';
import { diff } from 'deep-object-diff';
import { useFormik } from 'formik';
import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import MaskedInput from 'react-text-mask';
import ConfirmationDialog from './confirmationDialog';
import UserSubscriptions from './UserSubscriptions';
import UserGiftcards from './UserGiftcards';
import {
  setAdminPrivileges,
  editUserRole,
} from '../../../integrations/api/users';
import { FormHelperText } from '@mui/material';

import {
  isBrasilParaleloDomain,
  isUserAdmin,
  userHasRole,
} from '../../../utils/users';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  content: {
    padding: '2rem 0',
  },
  textField: {
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
    width: 300,
  },
  picture: {
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
    width: 150,
  },
  roleTitle: {
    marginBottom: theme.spacing(1),
    marginRight: '100%',
  },
  rolesBox: {
    marginBottom: theme.spacing(2),
  },
  role: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  editableField: {
    color: 'red',
  },
}));

let defaultTabs = [
  { name: 'Cadastro' },
  { name: 'Assinaturas' },
  { name: 'Cartões Presente' },
];

export default ({ data, submitHandler }) => {
  const updating = !isEmpty(data);
  const classes = useStyles();
  const [oldUserData] = useState(data);
  const [adminEnabled, setAdminEnabled] = useState(isUserAdmin(oldUserData));
  const [tagAdminEnabled, setTagAdminEnabled] = useState(
    userHasRole(oldUserData, 'admin-tag-editor')
  );
  const [portunusFrontendEnabled, setPortunusFrontendEnabled] = useState(
    userHasRole(oldUserData, 'portunus-frontend')
  );
  const [bpeiroEnabled, setBpeiroEnabled] = useState(
    userHasRole(oldUserData, 'bpeiro')
  );
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState('0');
  const history = useHistory();

  const formik = useFormik({
    initialValues: {
      enableReinitialize: true,
      ...data,
      id: data?._id,
      gender: data.gender || 'm',
    },
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
    },
  });

  const tabs = isBrasilParaleloDomain(oldUserData)
    ? [...defaultTabs, { name: 'Avançado' }]
    : defaultTabs;

  const keyToName = (key) => {
    const keyNames = {
      phone: 'telefone',
      name: 'nome',
      email: 'email',
    };
    return keyNames[key] || key;
  };

  const openConfirmDialog = (e) => {
    e.preventDefault();
    setConfirmDialog(true);
  };

  const closeConfirmDialog = (e) => {
    e.preventDefault();
    setConfirmDialog(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setConfirmDialog(false);
    setLoading(true);
    submitHandler(formik.values).then((res) => {
      setLoading(false);
      history.push(`/admin/users/details/${res._id}`);
    });
  };

  const handleTab = (__event, newValue) => {
    setTab(newValue);
  };

  const getFormikProps = (name, formik) => ({
    value: formik.values[name],
    onChange: formik.handleChange,
    name,
    error: formik.touched[name] && Boolean(formik.errors[name]),
    helperText: formik.touched[name] && formik.errors[name],
  });

  return (
    <>
      <form method="PUT" onSubmit={openConfirmDialog}>
        <TabContext value={tab}>
          <AppBar position="static" color="default">
            <Tabs value={tab} onChange={handleTab} aria-label="user-tabs">
              {tabs.map((tab, index) => (
                <Tab
                  disabled={!data._id}
                  label={tab.name}
                  value={index.toString()}
                  key={index}
                />
              ))}
            </Tabs>
          </AppBar>

          <TabPanel value={'0'} index={'0'} key={0} className={classes.content}>
            {!updating && (
              <Alert severity="info">
                {' '}
                Ao finalizar o cadastro básico abaixo, você poderá vincular
                assinaturas ao usuário que darão acesso para o mesmo na
                plataforma.
              </Alert>
            )}

            <br />

            <Grid container>
              {oldUserData.avatar && (
                <Grid item xs={12}>
                  <Image
                    className={classes.picture}
                    cloudName="hvzbb2hdx"
                    publicId={oldUserData.avatar.image}
                    version={oldUserData.avatar.version}
                  >
                    <Transformation
                      width="200"
                      height="200"
                      gravity="face"
                      radius="max"
                      background="#ffffff"
                      crop="thumb"
                    />
                  </Image>
                </Grid>
              )}

              <Grid item xs={12}>
                <TextField
                  className={classes.textField}
                  label={`Nome completo`}
                  defaultValue={oldUserData.name}
                  {...getFormikProps('name', formik)}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <FormControl variant="outlined" className={classes.textField}>
                  <InputLabel id="gender-label">Sexo</InputLabel>
                  <Select
                    native
                    label="Sexo"
                    name="gender"
                    disabled={updating}
                    {...getFormikProps('gender', formik)}
                    inputProps={{
                      name: 'gender',
                      id: 'gender-label',
                    }}
                  >
                    <option value={'m'}>Masculino</option>
                    <option value={'f'}>Feminino</option>
                  </Select>
                </FormControl>
                <TextField
                  className={classes.textField}
                  label={`Email`}
                  variant="outlined"
                  {...getFormikProps('email', formik)}
                  required
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  className={classes.textField}
                  label={`Produto`}
                  variant="outlined"
                  {...getFormikProps('plan', formik)}
                  disabled={true}
                  readOnly
                  InputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  className={classes.textField}
                  label={`Documento (CPF)`}
                  variant="outlined"
                  {...getFormikProps('doc', formik)}
                  disabled={updating}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  className={classes.textField}
                  label={`Telefone`}
                  {...getFormikProps('phone', formik)}
                  variant="outlined"
                  InputProps={{
                    inputComponent: phoneInputMask,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    formik.setFieldValue(
                      e.target.name,
                      e.target.value.replace(/[^0-9]/g, '')
                    );
                  }}
                />
                <TextField
                  className={classes.textField}
                  label={`Profissão`}
                  variant="outlined"
                  {...getFormikProps('profession', formik)}
                  disabled={updating}
                  InputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  className={classes.textField}
                  label={`Escolaridade`}
                  variant="outlined"
                  {...getFormikProps('scholling', formik)}
                  disabled={updating}
                  InputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  className={classes.textField}
                  label={`Data de Nascimento`}
                  {...getFormikProps('birthday', formik)}
                  defaultValue={
                    oldUserData.birthday
                      ? moment(oldUserData.birthday).format('DD/MM/YYYY')
                      : ''
                  }
                  variant="outlined"
                  disabled={updating}
                  InputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  className={classes.textField}
                  label={`CEP`}
                  defaultValue={
                    oldUserData.address ? oldUserData.address.postal_code : ''
                  }
                  variant="outlined"
                  {...getFormikProps('address.postal_code', formik)}
                  disabled={updating}
                  InputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  className={classes.textField}
                  label={`País`}
                  defaultValue={
                    oldUserData.address ? oldUserData.address.country : ''
                  }
                  variant="outlined"
                  {...getFormikProps('address.country', formik)}
                  disabled={updating}
                  InputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  className={classes.textField}
                  label={`Estado`}
                  defaultValue={
                    oldUserData.address ? oldUserData.address.state : ''
                  }
                  variant="outlined"
                  {...getFormikProps('address.state', formik)}
                  disabled={updating}
                  InputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  className={classes.textField}
                  label={`Cidade`}
                  defaultValue={
                    oldUserData.address ? oldUserData.address.city : ''
                  }
                  variant="outlined"
                  {...getFormikProps('address.city', formik)}
                  disabled={updating}
                  InputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  className={classes.textField}
                  label={`Bairro`}
                  defaultValue={
                    oldUserData.address ? oldUserData.address.district : ''
                  }
                  variant="outlined"
                  {...getFormikProps('address.district', formik)}
                  disabled={updating}
                  InputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  className={classes.textField}
                  label={`Endereço`}
                  defaultValue={
                    oldUserData.address ? oldUserData.address.address : ''
                  }
                  variant="outlined"
                  {...getFormikProps('address.address', formik)}
                  disabled={updating}
                  InputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  className={classes.textField}
                  label={`Número`}
                  defaultValue={
                    oldUserData.address ? oldUserData.address.number : ''
                  }
                  variant="outlined"
                  {...getFormikProps('address.number', formik)}
                  disabled={updating}
                  InputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  className={classes.textField}
                  label={`Complemento`}
                  defaultValue={
                    oldUserData.address ? oldUserData.address.complement : ''
                  }
                  variant="outlined"
                  {...getFormikProps('address.complement', formik)}
                  disabled={updating}
                  InputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid item xs={12} className={classes.rolesBox}>
                <Grid item xs={12} className={classes.roleTitle}>
                  <Typography variant="body1">Papéis:</Typography>
                </Grid>

                {oldUserData.roles
                  ? oldUserData.roles.map((role, i) => (
                    <Chip
                      label={role.role}
                      className={classes.role}
                      color="primary"
                      key={i}
                    />
                  ))
                  : 'Este membro não possui papéis de acesso ainda...'}
              </Grid>
            </Grid>
          </TabPanel>

          <TabPanel value={'1'} index={'1'} key={1} className={classes.content}>
            <UserSubscriptions />
          </TabPanel>

          <TabPanel value={'2'} index={'2'} key={2} className={classes.content}>
            <UserGiftcards />
          </TabPanel>

          {isBrasilParaleloDomain(oldUserData) ? (
            <TabPanel
              value={'3'}
              index={'3'}
              key={3}
              className={classes.content}
            >
              <FormControlLabel
                control={
                  <Switch
                    checked={adminEnabled}
                    onChange={async () => {
                      await setAdminPrivileges({
                        userId: formik.values.id,
                        enableAdmin: !adminEnabled,
                      });
                      setAdminEnabled(!adminEnabled);
                      window.location.reload();
                    }}
                  />
                }
                label="Administrador"
              />

              <FormHelperText>
                Configura esse funcionário como administrador ou não. Isso
                permitirá acesso ao painel de administração, mas removerá o
                acesso a plataforma.
              </FormHelperText>

              <FormControlLabel
                control={
                  <Switch
                    checked={bpeiroEnabled}
                    onChange={async () => {
                      await editUserRole(
                        formik.values.id,
                        'bpeiro',
                        !bpeiroEnabled
                      );
                      setBpeiroEnabled(!bpeiroEnabled);
                      window.location.reload();
                    }}
                  />
                }
                label="BPeiro"
              />

              <FormHelperText>
                Configura esse funcionário com a role "bpeiro".
              </FormHelperText>

              <FormControlLabel
                control={
                  <Switch
                    checked={tagAdminEnabled}
                    onChange={async () => {
                      await editUserRole(
                        formik.values.id,
                        'admin-tag-editor',
                        !tagAdminEnabled
                      );
                      setTagAdminEnabled(!tagAdminEnabled);
                      window.location.reload();
                    }}
                  />
                }
                label="Editor de tags"
              />

              <FormHelperText>
                Configura esse funcionário como editor de tags de mídia.
              </FormHelperText>

              <FormControlLabel
                control={
                  <Switch
                    checked={portunusFrontendEnabled}
                    onChange={async () => {
                      await editUserRole(
                        formik.values.id,
                        'portunus-frontend',
                        !portunusFrontendEnabled
                      );
                      setPortunusFrontendEnabled(!portunusFrontendEnabled);
                      window.location.reload();
                    }}
                  />
                }
                label="Portunus Frontend"
              />

              <FormHelperText>
                Dá permissão de acesso ao Frontend do Portunus.
              </FormHelperText>
            </TabPanel>
          ) : null}

          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={!formik.dirty || loading}
          >
            Salvar
          </Button>
        </TabContext>
      </form>

      {confirmDialog && (
        <ConfirmationDialog
          onCancel={closeConfirmDialog}
          onConfirm={handleSubmit}
          title="Os seguintes campos serão alterados:"
          body={Object.keys(diff(data, formik.values))
            .map(keyToName)
            .join(', ')}
          cancelText="Cancelar"
          confirmText="Atualizar"
        />
      )}
    </>
  );
};

function phoneInputMask(props) {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        '(',
        /\d/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      placeholderChar={'_'}
      showMask
    />
  );
}
